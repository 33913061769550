import React, { useState } from 'react'
import './modelssubnavigation.scss';
import WalkBehindScrubbers from './WalkBehindScrubbers';
import RideOnFloorScrubbers from './RideOnFloorScrubbers';
import Sweepers from './Sweepers';
import Burnishers from './Burnishers';
import Edge from './Edge';

export default function ModelsSubNavigation() {
    const [selectedIndex, setSelectedIndex] = useState(1);
    const handleSelectedIndex = (e) => {
        setSelectedIndex(e.target.value);
      };
    
    return (
        <>
        <div className="models-sub-navigation">
         <ul>
         <li onClick={handleSelectedIndex} value="1" className={selectedIndex === 1 ? "active" : ""}>Walk Behind Floor Scrubbers</li>
         <li onClick={handleSelectedIndex} value="2" className={selectedIndex === 2 ? "active" : ""}>Ride On Floor Scrubbers</li>
         <li onClick={handleSelectedIndex} value="3" className={selectedIndex === 3 ? "active" : ""}>Sweepers</li>
         <li onClick={handleSelectedIndex} value="4" className={selectedIndex === 4 ? "active" : ""}>Burnishers</li>
         <li onClick={handleSelectedIndex} value="5" className={selectedIndex === 5 ? "active" : ""}>Edge</li>
          </ul>
        </div>
          {selectedIndex === 1 ? <WalkBehindScrubbers show={`show`} />: <WalkBehindScrubbers show={`hide`}/>} 
          {selectedIndex === 2 ? <RideOnFloorScrubbers show={`show`}/> : <RideOnFloorScrubbers show={`hide`} />}
          {selectedIndex === 3 ? <Sweepers show={`show`}/> : <Sweepers show={`hide`} />}
          {selectedIndex === 4 ? <Burnishers show={`show`}/> : <Burnishers show={`hide`}/>}
          {selectedIndex === 5 ? <Edge show={`show`}/> : ""}

          </>
    )
}
