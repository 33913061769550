import React from 'react'
import './requestdemo.scss'
import { CgClose } from 'react-icons/cg'

import Layout from '../Layout'

export default function RequestDemo(props) {
    const closeRequestForm = () => {
        props.close()
    }
    return (
        <div className="request-demo-wrapper">
                <div className="demo-header">
                    <div><h4>Request a <span className="blue">Demo</span></h4></div>
                    <div><CgClose onClick={closeRequestForm}/></div>
                </div>
            <div className="request-demo-form">
                <label>Company Information</label>
                <input type="text" placeholder="Company Name"></input>
                <input type="text" placeholder="Street Adress"></input>
                <input type="text" placeholder="Adress 2"></input>
                <input type="text" placeholder="City"></input>
                <input type="state" placeholder="State"></input>
                <input type="state" placeholder="Zip"></input>
                <input type="state" placeholder="Country"></input>
                <label>Primary Contact Information</label>
                <input type="text" placeholder="Full Name"></input>
                <input type="text" placeholder="Phone"></input>
                <input type="state" placeholder="Email"></input>
                <button>Submit Request</button>
            </div>
        </div>

    )
}
