import React from 'react'
import ProductNavCard from './ProductNavCard'
import ReconImage from "../ModelImages/recon-render.jpg";
import MicroMiniImage from "../ModelImages/micro-mini.jpg";
import SportImage from "../ModelImages/sport.jpg";
import HeroImage from "../ModelImages/hero.jpg";
import CarbonImage from "../ModelImages/carbon.jpg";
import './modelsubmenu.scss';

export default function WalkBehindScrubbers(props) {
    return (
        <div className={`models ${props.show}`}>
            <ProductNavCard
              image={ReconImage}
              title="Recon"
              moreLink="/models/Recon/overview"
            />
            <ProductNavCard
              image={MicroMiniImage}
              title="Micro-Mini"
              moreLink=""
            />
            <ProductNavCard
              image={SportImage}
              title="Sport"
              moreLink=""
            />
            <ProductNavCard
              image={CarbonImage}
              title="Carbon"
              moreLink="/models/Carbon/overview"
            />
            <ProductNavCard
              image={HeroImage}
              title="Hero"
              moreLink=""
            />
        </div>
    )
}
