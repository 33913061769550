import React, { useState } from 'react'
import './navigation.scss'
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io'
import { Link } from 'gatsby'

export default function MobileDropDownMenu(props) {
    console.log(props)
    const [menu, setMenu] = useState('')
    const showSubMenu = (event) => {
        setMenu(event.target.id)
        if(menu !== '') {
            setMenu('')
            setMenu(event.target.id)
        }
        if(event.target.id === menu) {
            setMenu('')
        }
        console.log(props)
    }
    const showRequestForm = () => {
        props.open()
    }
     return (    
         <div className= "drop-down-menu">
            <span className="mobile-link" id="Models" onClick={showSubMenu}>Models{menu === "Models" ? < IoMdArrowDropdown />:<IoMdArrowDropright  />}</span>
            {/* If adding links add to the hight in navigation scss !import */}
             <ul className={menu === "Models" ? "models show" : " models hide"}>
             <li className="li-header">Welk Behind</li>
             <li><Link to="/models/Recon/overview">Recon</Link></li>
             <li><Link to="/">Micro Mini</Link></li>
             <li><Link to="/">Sport</Link></li>
             <li><Link to="/models/Carbon/overview">Carbon</Link></li>
             <li><Link to="/">Hero</Link></li>
             <li className="li-header">Ride On</li>
             <li><Link to="/">Pro</Link></li>
             <li><Link to="/">CRZ</Link></li>
             <li><Link to="/">EX</Link></li>
             <li><Link to="/">XR</Link></li>
             <li className="li-header">Sweepers</li>
             <li><Link to="/">GT</Link></li>
             <li><Link to="/">VR</Link></li>
             <li className="li-header">Burshers</li>
             <li><Link to="/">200</Link></li>
             <li className="li-header">Edge</li>
             <li><Link to="/">ISO Edge</Link></li>
             <li><Link to="/">Nano Corded</Link></li>
             <li><Link to="/">Nano Cordless</Link></li>
         </ul>
        <span className="mobile-link" id="Discover" onClick={showSubMenu}>Discover{menu === "Discover" ? < IoMdArrowDropdown />:<IoMdArrowDropright  />}</span>
        <ul className={menu === "Discover" ? "discover show" : "discover hide"}>
            <li><Link to="/about" >About Tomcat</Link></li>
            <li><Link to="/" >Tomcat Engine</Link></li>
            <li><Link to="/tomcat-team" >Team Tomcat</Link></li>
            <li><Link to="/partners" >Partners</Link></li>
            <li><Link to="/gallery" >Gallery</Link></li>
        </ul>
            <span className="mobile-link" id="Support" onClick={showSubMenu}>Support{menu === "Support" ? < IoMdArrowDropdown />:<IoMdArrowDropright  />}</span>
        <ul className={menu === "Support" ? "support show" : "support hide"}>
        {/* <li className="li-header">Welk Behind</li> */}
            <li><Link to="/" >Register a Machine</Link></li>
            <li><Link to="/downloads" >Downloads</Link></li>
            <li><Link to="/" >Warranty</Link></li>
         </ul>
         <span className="mobile-link" id="News"><Link to="/news">News</Link></span>
         <span className="mobile-link" id="News"><a href onClick={showRequestForm}>Request a Demo</a></span>
        </div>
    )
}
