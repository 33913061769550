import React from 'react'

import ProImage from "../ModelImages/pro.jpg";
import CrzImage from "../ModelImages/crz.jpg";
import ExImage from "../ModelImages/ex.jpg";
import XrImage from "../ModelImages/xr.jpg";
import ProductNavCard from './ProductNavCard';

export default function RideOnFloorScrubbers(props) {
    return (
        <div className={`models ${props.show}`}>
            <ProductNavCard image={ProImage} title="Pro" moreLink="" />
            <ProductNavCard image={CrzImage} title="CRZ" moreLink="" />
            <ProductNavCard image={ExImage} title="EX" moreLink="" />
            <ProductNavCard image={XrImage} title="XR" moreLink="" />
        </div>
    )
}
