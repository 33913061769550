import React from 'react'
import ProductNavCard from './ProductNavCard'
import GtImage from "../ModelImages/gt.jpg";
import VrImage from "../ModelImages/vr.jpg";


export default function Sweepers(props) {
    return (
        <div className={`models ${props.show}`}>
            <ProductNavCard image={GtImage} title="GT" moreLink="" />
            <ProductNavCard image={VrImage} title="VR" moreLink="" />
        </div>
    )
}
