import React from "react";
import styled from "styled-components";
import Logo from "../components/navigation/images/tomcatequip-top.png";
import './footer.scss';
import { Link } from "gatsby";
import PaddedLayout from "./PaddedLayout";

const Footer = () => {
  return (
    <footer>
      <div>
        <ul id="link-list">
          <li><Link className="main" to="/about">About </Link></li>
          <li><Link className="main" to="/contact">Contact Us </Link></li>
          <li><Link className="main" to="/careers">Careers </Link></li>
          <li><Link className="main" to="/request-brochure">Request Brochure </Link></li>
          <li><Link className="main" to="/subscribe">Subscribe </Link></li>
          <li><Link className="main" to="/press-releases">Press Releases </Link></li>
          <li><Link className="main" to="/privacy">Privacy </Link></li>
          <li><Link className="main" to="/legal">Legal </Link></li>
        </ul>
      </div>
      <div>
        <ul id="scrubber-list">
          <li className="title" >Welk Behind Scrubbers</li>
          <li><Link to="">Recon</Link></li>
          <li><Link to="">Micro Mini</Link></li>
          <li><Link to="">Sport</Link></li>
          <li><Link to="">Carbon</Link></li>
          <li><Link to="">Hero</Link></li>
          <li className="title">Ride One Scrubbers</li>
          <li><Link to="">Pro</Link></li>
          <li><Link to="">CRZ</Link></li>
          <li><Link to="">EX</Link></li>
          <li><Link to="">XR</Link></li>
          <li className="title">Sweepers</li>
          <li><Link to="">GT</Link></li>
          <li><Link to="">VR</Link></li>
          <li className="title">Burnishers</li>
          <li><Link to="">200</Link></li>
          <li className="title">Edge</li>
          <li><Link to="">ISO</Link></li>
          <li><Link to="">Nano</Link></li>
        </ul>
        </div>
      <div>
        <ul>
          <li className="title">Why Tomcat</li>
          <li><Link to="">Owner Reviews</Link></li>
          <li><Link to="">Our History</Link></li>
          <li><Link to="">Our Partners</Link></li>
          <li><Link to="">Factory Tour</Link></li>
          <li><Link to="">Awards</Link></li>
          <li><Link to="">Engines</Link></li>
          <li><Link to="">Coastal Edition</Link></li>
          <li><Link to="">Tomcat System</Link></li>
        </ul>
        </div>
      <div>
        <ul>
          <li className="title">Community</li>
          <li><Link to="">Tomcat Events</Link></li>
          <li><Link to="">Team Tomcat</Link></li>
          <li><Link to="">Tomcat Network</Link></li>
          <li><Link to="">Tomcat Cares</Link></li>
          <li><Link to="">Tomcat Videos</Link></li>
          <li><Link to="">Tomcat Gear</Link></li>
        </ul>
        </div>
      <div>
        <ul>
          <li className="title">Help and Support</li>
          <li><Link to="">Owners Manuals</Link></li>
          <li><Link to="">Safety</Link></li>
          <li><Link to="">FAQ</Link></li>
          <li><Link to="">Find a Dealer</Link></li>
          <li><Link to="">Warranty</Link></li>
        </ul>
      </div>
      <div className="logo-copyright">
      <img src={Logo} alt="logo" className="logo"/>
      </div>
      <div className="copyright-text"> 
      <p>©2021 Tomcat Scrubber Company, Inc. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
