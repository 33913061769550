import React from "react";
import styled from "styled-components";
import "../styles.scss";

const PageLayout = styled.div``;

const PaddedLayout = ({ children }) => {
  return (
      <PageLayout className="padded-layout">
        {children}
      </PageLayout>
  );
};

export default PaddedLayout;
