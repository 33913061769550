import React from "react";
// import Navigation from "./navigation/Navigation";
import Navigation from './Navigation-L/Navigation'
import styled from "styled-components";
import Footer from "./Footer";
import "../styles.scss";

const PageLayout = styled.div``;

const Layout = ({ children }) => {
  return (
      <PageLayout>
        <Navigation />
        {children}
        <Footer />
      </PageLayout>
  );
};

export default Layout;
