import React from 'react'
import ProductNavCard from './ProductNavCard'
import IsoImage from "../ModelImages/iso.jpg";
import NanoCordImage from "../ModelImages/nano-cord.jpg";
import NanoCordlessImage from "../ModelImages/nano-cordless.jpg";
export default function Edge(props) {
    return (
        <div className={`models ${props.show}`}>
            <ProductNavCard image={IsoImage} title="ISO Edge" moreLink="" />
            <ProductNavCard image={NanoCordImage} title="Nano Corded" moreLink="" />
            <ProductNavCard image={NanoCordlessImage} title="Nano Cordless" moreLink="" />
        </div>
    )
}
