import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import './productnavcard.scss';



const ProductNavCard = (props) => {
  return (
    <div className="product-container">
      <img src={props.image} />
      <h1 style={{ margin: "0", textAlign: "center" }}>{props.title}</h1>
      <Link to={props.moreLink}>
        Learn More
      </Link>
    </div>
  );
};

export default ProductNavCard;
