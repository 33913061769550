import React, { useState } from 'react'
import { Link } from 'gatsby';
import './navigation.scss'
import TomcatLogo from "../../images/TC_Logo.png";
import MobileDropDownMenu from './MobileDropDownMenu';
import { CgMenu, CgClose } from 'react-icons/cg'
import ModelsSubNavigation from '../ModelsSubMenu/ModelsSubNavigation';
import RequestDemo from '../RequestDemo/RequestDemo';
import Discover from './DiscoverDropDown/Discover';

export default function Navigation() {
    const [showMenu, setShowMenu] = useState('')
    const [showModels, setShowModels] = useState(false)
    const [showRequestForm, setShowRequestForm] = useState(false)
    const [showDiscover, setShowDiscover] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const showDropDownMenu = () => {
        setShowMenu(!showMenu)
    }    
    const showModelsMenu = () => {
        setShowModels(!showModels)
    }
    const openDemoRequest = () => {
        setShowRequestForm(true)
        console.log("Open Request Form Called")
    }
    const closeDemoRequest = () => {
        setShowRequestForm(false)
    }
    return (
        <>
        <nav className="mobile-navigation">
            <div><Link to="/"><img src={TomcatLogo} alt="Tomcat Logo" /></Link></div>
            <div>{showMenu  ?  <CgClose onClick={showDropDownMenu} />: <CgMenu onClick={showDropDownMenu}/>}</div>
        </nav>
        <nav className="desktop-navigation">
        <div>
            <ul>
                <li><a href onClick={showModelsMenu}>Models</a></li>
                <li className="discover"><a href >Discover</a> 
                    <ul className="discover-sub-menu">
                        <li><Link to="/about" >About Tomcat</Link></li>
                        <li><Link to="/tomcat-difference" >Tomcat Engine</Link></li>
                        <li><Link to="/tomcat-team" >Team Tomcat</Link></li>
                        <li><Link to="/partners" >Partners</Link></li>
                        <li><Link to="/gallery" >Gallery</Link></li>
                    </ul>
                </li>
                <li className="discover"><a href >Support</a> 
                    <ul className="discover-sub-menu">
                        <li><Link to="/register-machine" >Register a Machine</Link></li>
                        <li><Link to="/downloads" >Downloads</Link></li>
                        <li><Link to="/warranty" >Warranty</Link></li>
                    </ul>
                </li>
                <li><Link to="/news">News</Link></li>
            </ul>
        </div>

        <div><Link to="/"><img src={TomcatLogo} alt="Tomcat Logo" /></Link></div>
        <div><button onClick={openDemoRequest}>Request a Demo</button></div>

        </nav>
        <div className={showMenu ? "mobile-drop-down-menu-wrapper show" :"mobile-drop-down-menu-wrapper hide"}>
            <MobileDropDownMenu open={openDemoRequest}/>
        </div>
        <div className={showModels ? "models-sub-menu-wrapper show" : "models-sub-menu-wrapper hide"}>
            <ModelsSubNavigation />
        </div>
        {showRequestForm ?  <RequestDemo close={closeDemoRequest}/> : ""}
       

        </>
    )
}
