import React from 'react'
import ProductNavCard from './ProductNavCard'
import Image200 from "../ModelImages/200.jpg";

export default function Burnishers(props) {
    return (
        <div className={`models ${props.show}`}>
            <ProductNavCard image={Image200} title="200" moreLink="" />
        </div>
    )
}
